import Axios from 'axios'

import Storage, { TOKEN_STORAGE_KEY } from './storage'

const axios = Axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
})

axios.interceptors.request.use((options) => {
  let token
  if (!options.baseURL) {
    token = Storage.get<string>(TOKEN_STORAGE_KEY)
  } else {
    token = Storage.get<string>(options.baseURL + '_' + TOKEN_STORAGE_KEY)
  }
  if (token !== null && options.headers) {
    options.headers.Authorization = token
  }
  options.headers.xmg8 = true
  return options
})

export default axios
