import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'

import { RejectedValue } from 'src/libs/error.handler'
import eventEmitter from 'src/libs/event.emitter'

import GradientButton from 'src/components/buttons/GradientButton'
import BaseRoundedInput from 'src/components/inputs/BaseRoundedInput'

import { AppDispatch } from 'src/store'
import { loginAction, selectIsRegistred, selectUserdata } from 'src/store/auth'
import { LoginReq } from 'src/store/auth/auth.api'

function LoginPage() {
  const dispatch = useDispatch<AppDispatch>()
  const location = useLocation()
  const userdata = useSelector(selectUserdata)
  const registred = useSelector(selectIsRegistred)

  const state = (location.state || { from: '/' }) as { from: string }
  const [{ errors, message: errMsg }, setError] = useState<RejectedValue>({})
  const [requireTwoFA, setRequireTwoFA] = useState(false)

  const [data, setData] = useState<LoginReq>({
    email: '',
    password: '',
    twoFACode: '',
    
  })

  const handleLogin = useCallback(() => {
    dispatch(loginAction(data))
  }, [data])

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setData((prev) => ({ ...prev, [name]: value }))
  }, [])

  useEffect(() => {
    const failedCallback = (error: RejectedValue) => {
      if (error.message === '2fa code required') {
        setRequireTwoFA(true)
      } else {
        setError(error)
      }
    }

    eventEmitter.on(loginAction.rejected.type, failedCallback)

    return () => {
      eventEmitter.off(loginAction.rejected.type, failedCallback)
    }
  }, [state.from])

  useEffect(() => {
    if (userdata) {
      setError({})
    }
  }, [userdata, state.from])

  if (!registred) {
    return <Navigate to='/signup' replace />
  }

  if (userdata) {
    return <Navigate to={state.from} replace />
  }

  return (
    <div className='m-auto flex w-full max-w-96 flex-col gap-4 px-4'>
      <div className='text-center'>
        <h4 className='mb-4'>Login</h4>
        <div className='text-subtitle'>Welcome to gunbot monitor!</div>
      </div>
      {!requireTwoFA ? (
        <>
          <div className='flex flex-col gap-1'>
            <label htmlFor='login-email'>Email *</label>
            <BaseRoundedInput
              id='login-email'
              className='rounded-md'
              inputClassName='h-9 text-sm'
              value={data.email}
              name='email'
              onChange={handleInputChange}
            />
            {errMsg && <span className='text-error'>{errMsg}</span>}
            {errors?.email && <span className='text-error'>{errors.email[0].message}</span>}
          </div>
          <div className='flex flex-col gap-1'>
            <label htmlFor='login-password'>Password *</label>
            <BaseRoundedInput
              id='login-password'
              className='rounded-md'
              inputClassName='h-9 text-sm'
              type='password'
              value={data.password}
              name='password'
              onKeyEnter={handleLogin}
              onChange={handleInputChange}
            />
            {errors?.password && <span className='text-error'>{errors.password[0].message}</span>}
          </div>
        </>
      ) : (
        <div className='flex flex-col gap-1'>
          <label htmlFor='login-password'>2fa code *</label>
          <BaseRoundedInput
            id='login-password'
            className='rounded-md'
            inputClassName='h-9 text-sm'
            value={data.twoFACode}
            autoComplete='off'
            name='twoFACode'
            onKeyEnter={handleLogin}
            onChange={handleInputChange}
          />
          {errMsg && <span className='text-error'>{errMsg}</span>}
          {errors?.twoFACode && <span className='text-error'>{errors.twoFACode[0].message}</span>}
        </div>
      )}
      <GradientButton className='mt-4 rounded-full' fontSize='md' onClick={handleLogin}>
        {requireTwoFA ? 'Verify' : 'Login'}
      </GradientButton>
    </div>
  )
}

export default LoginPage
